// Labels för hantering av användare
const userProfileLabels = { 
    username: {
        0: 'Användarnamn',
        1: 'text'
    },
    firstname: {
        0: 'Förnamn',
        1: 'text'
    },
    lastname: {
        0: 'Efternamn',
        1: 'text'
    },
    email: {
        0: 'E-post',
        1: 'email'
    },
    tel: {
        0: 'Tel.',
        1: 'text'
    },
    adress: {
        0: 'Adress',
        1: 'text'
    },
    postnummer: {
        0: 'Postnummer',
        1: 'number'
    },
    password: {
        0: 'Lösenord',
        1: 'password'
    }
}  

const userLabels = { 
    username: {
        0: 'Användarnamn',
        1: 'text'
    },
    firstname: {
        0: 'Förnamn',
        1: 'text'
    },
    lastname: {
        0: 'Efternamn',
        1: 'text'
    },
    email: {
        0: 'E-post',
        1: 'email'
    },
    tel: {
        0: 'Tel.',
        1: 'text'
    },
    adress: {
        0: 'Adress',
        1: 'text'
    },
    postnummer: {
        0: 'Postnummer',
        1: 'number'
    },
    type_of_member: {
      0: 'Medlemstyp',
      1: 'text',
      2: ['Välj','1', '2', '3','4','5','6']
    },
    registerd: {
      0: 'Registrerad',
      1: 'date'
    },
    password: {
        0: 'Lösenord',
        1: 'password'
    },
    condition: {
        0: 'Lösenord'
    }
}  

// Labels för priser
const priceLabels = { 
    type_of_object: {
        0: 'Meldemstyp',
        1: 'text'
    },
    member_fee: {
        0: 'Medlemsavgift',
        1: 'number'
    },
    pool_table: {
        0: 'Pool bord',
        1: 'number'
    },
    darts: {
        0: 'Darts',
        1: 'number'
    }
} 

// Labels för bokningsbara objekt
const bookableObjectsLabels = { 
    type_of_object: {
        0: 'Namn',
        1: 'text',
    },
    bookable: {
        0: 'Bokningsbar',
        1: 'text',
        2: ['Välj','1', '0']
    },
    price_group: {
        0: 'Prisgrup',
        1: 'number',        
        2: ['Välj','1', '8']
    }
} 

// Labels för listing selected bookings
const selectedBookingsLabels = { 
    firstname: {
        0: 'Förnamn',
        1: 'text',
    },
    lastname: {
        0: 'Efternamn',
        1: 'text',
    },
    email: {
        0: 'Email',
        1: 'text',
    },
    type_of_object: {
        0: 'Bord',
        1: 'text',
    },
    timeId: {
        0: 'Tidpunt',
        1: 'text',        
    }
} 

// Labels för listing selected bookings
const memberFeeLabels = { 
    firstname: {
        0: 'Förnamn',
        1: 'text',
    },
    lastname: {
        0: 'Efternamn',
        1: 'text',
    },
    email: {
        0: 'Email',
        1: 'text',
    },
    member_fee: {
        0: 'Medlemsavgift',
        1: 'number',
    },
    total_bookings: {
        0: 'Bokningsavgifter',
        1: 'number',        
    }
} 

// Medlemstyper
const userTypes = {1: 'MEMBER',2:'FULL_MEMBER',3:'SNOOKER_MEMBER',4:'JUNIOR_FULLMEMBER',5:'ROYAL_MEMBER',6:'REGISTERED_USER'};

const availableTimes = [
    { id: '1', type_of_object: '07:00–08:00' },
    { id: '2', type_of_object: '08:00–09:00' },
    { id: '3', type_of_object: '09:00–10:00' },
    { id: '4', type_of_object: '10:00–11:00' },
    { id: '5', type_of_object: '11:00–12:00' },
    { id: '6', type_of_object: '12:00–13:00' },
    { id: '7', type_of_object: '13:00–14:00' },
    { id: '8', type_of_object: '14:00–15:00' },
    { id: '9', type_of_object: '15:00–16:00' },
    { id: '10', type_of_object: '16:00–17:00' },
    { id: '11', type_of_object: '17:00–18:00' },
    { id: '12', type_of_object: '18:00–19:00' },
    { id: '13', type_of_object: '19:00–20:00' },
    { id: '14', type_of_object: '20:00–21:00' },
    { id: '15', type_of_object: '21:00–22:00' },
    { id: '16', type_of_object: '22:00–23:00' },
    { id: '17', type_of_object: '23:00–00:00' },
    { id: '18', type_of_object: '00:00–01:00' }
  ];
 	 	 

export {
    userLabels,
    priceLabels,
    bookableObjectsLabels,
    selectedBookingsLabels,
    userTypes,
    availableTimes,
    userProfileLabels,
    memberFeeLabels
}