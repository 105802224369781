import React, { useState, useEffect } from 'react';
import HoverInfoBox from './HoverInfoBox';

function Grid({maxItems,maxSelectable,bookedAllItems,availableTimes,availableTables}) {
    //console.log(availableTimes.length);
    //console.log(availableTables);
 
/*     const [isMouseDown, setIsMouseDown] = useState(false);
    const [startX, setStartX] = useState(0);
    const [startY, setStartY] = useState(0);
    const [endX, setEndX] = useState(0);
    const [endY, setEndY] = useState(0);
 */

    const [elements, setElements] = useState([]);
    const [selectedItemsCount, setSelectedItemsCount] = useState(0);
    const [hoveredItem, setHoveredItem] = useState(null);
    const [infoBoxVisible, setInfoBoxVisible] = useState(false);
    const today = new Date();      

    useEffect(() => {
        /*const itemsContainer = document.getElementById('items');
 
  
        if(itemsContainer){            
            const unselectableItems = itemsContainer.getElementsByClassName('unselectable');
            Array.from(unselectableItems).forEach(element => {         
                //element.classList.remove('unselectable');
            });
        }*/

      // Function generating a list of elements
      const generatedElements = generateList(maxItems,bookedAllItems,today);
      setElements(generatedElements);
    }, [bookedAllItems,infoBoxVisible]);
  
    useEffect(() => {
      // Function accessing the elements after the state has been updated
      InitializeGrid(maxSelectable);
    }, [elements,maxSelectable]);

       
    function isDateBeforeToday(dateString,today) {
        // Convert the date string to a Date object
        const inputDate = new Date(dateString);
        
        // Get the current date and set the time to midnight
        today.setHours(0, 0, 0, 0);
      
        // Compare the dates
        return inputDate < today;
      }
    
    function generateList(maxItems,bookedAllItems,today) {    

        const rows = availableTimes.length - 1;
        const columns = availableTables.length - 1;
        const grid = [];

        
        const currentTime = new Date().toTimeString().slice(0, 5);
        const itemsId = availableTimes.map(item => item.id);
        const itemsName = availableTables.map(item => item.id);
        
    
        for (let row = 0; row <= rows; row++) {
            for (let col = 0; col <= columns; col++) {
                const itemNumber = (row - 1) * columns + col;
                if (itemNumber <= maxItems) {

                // Kollar om en bokning med en viss time och table id har matchande
                // värde i bookedAllItems objektet
                const isUnselectable = bookedAllItems.find(
                    item => item.timeId === String(itemsId[row]) && item.tableId === String(itemsName[col])                   
                );

                // Visar namn på den som bokat
                let bookingId = isUnselectable ? 
                    'Namn: ' + isUnselectable.firstname + ' ' + isUnselectable.lastname + '<br />' + 'Tel: ' +  isUnselectable.tel: 
                    ''
                ;

                // Kollar om radens time är sama eller före nuvaraden klockslag
                const isUnavailable = availableTimes[row]['type_of_object'] <= currentTime;
     
                    //console.log(availableTimes[row]['type_of_object']);
                    //console.log(row);
                
                

                // unavailable = obokbar då tiden har gått ut
                // unselectable = obokbar då den renad är bokad/avaktiverad
                // selectable = går att boka
                let classNames = 'selectable';

                if(bookingId) {
                    //classNames = classNames.concat(' unselectable booked');
                    classNames = classNames.concat(' booked');
                }

                // BUG! sista raden är aldrig unselectable :(
                /*if( (!isUnavailable || times[row] === '00.00-01.00') && !isDateBeforeToday(selectedDate,today) ) {
                    if(isUnselectable) {
                        classNames = 'unselectable';
                    }
                    else {
                        classNames = 'selectable';
                    }
                }*/

                grid.push(
                    <div
                        key={`row-${row}-col-${col}`}
                        data-id-time={itemsId[row]}
                        data-id-table={itemsName[col]}
                        className={`list-item grid-item ${classNames}`}
                        onMouseEnter={() => handleMouseEnter({ row, col, time: availableTimes[row]['type_of_object'] })}
                        onMouseLeave={handleMouseLeave}
                    >
                        {availableTimes[row]['type_of_object']}
                        {infoBoxVisible && hoveredItem && hoveredItem.row === row && hoveredItem.col === col && isUnselectable && (
                            <HoverInfoBox props={isUnselectable} />
                        )}
                    </div>
                    );
                }
            }
        }
        return grid;
    }   

     useEffect(() => {
        let timer;
        if (hoveredItem !== null) {
            timer = setTimeout(() => {
                setInfoBoxVisible(true);
            }, 1000);
        } else {
            setInfoBoxVisible(false);
        }
        return () => clearTimeout(timer);
    }, [hoveredItem]);

    const handleMouseEnter = (item) => {
        setHoveredItem(item);
    };

    const handleMouseLeave = () => {
        setHoveredItem(null);
    };

    function InitializeGrid(maxSelectable) {
    
        const grid = document.getElementById('items');
        //const grid = itemsList.current;
        //console.log(grid + "test");
        const items = grid.getElementsByClassName('selectable');
        //const items = itemsListItem;
        let isMouseDown = false;
        let startX, startY, endX, endY;
        let lastSelectedItem = null;
        let selectedCount = 0;

        const squareOverlay = document.createElement('div');
        squareOverlay.classList.add('square-overlay');
        document.body.appendChild(squareOverlay);

        function handleItemMouseDown(event) {  
            
            const selectedItem = event.target;

            //if(!selectedItem.classList.contains('unselectable')) {

            
            
                //console.log('mouse down1');
                //console.log('fyri' + startX);
                isMouseDown = true;
                startX = event.clientX;
                startY = event.clientY;
                
                
        
                if (event.ctrlKey || event.metaKey) {
                    //console.log('selectedItem class list before condition:', selectedItem.classList);
                    //console.log(selectedCount + ' ' + maxSelectable + ' ' + selectedItemsCount);

                    if (selectedItem.classList.contains('selected')) {
                        selectedItem.classList.remove('selected');
                        selectedCount--;
                        //console.log(selectedItem.classList.contains('selected'));
                        setSelectedItemsCount(prevCount => prevCount - 1);
                    } 
                    else if (selectedCount < maxSelectable) {
                        //console.log('mouse selected');
                        selectedItem.classList.add('selected');
                        selectedCount++;
                        setSelectedItemsCount(prevCount => prevCount + 1);
                        console.log('test ' + selectedCount);
                        //console.log(selectedCount + ' ' + maxSelectable);
                        
                    }                
                } 
                else if (event.shiftKey && lastSelectedItem) {
                    selectItemsInRange(selectedItem);
                } 
                else {
                    clearSelection();                
                    selectedItem.classList.add('selected');
                    lastSelectedItem = selectedItem;
                    selectedCount = 1;
                    console.log(selectedCount);
                }
        
                grid.addEventListener('mousemove', handleItemMouseMove);      
            //}    
        }



        function handleItemMouseMove(event) {            
            if (isMouseDown) {
                endX = event.clientX;
                endY = event.clientY;
    
                Array.prototype.forEach.call(items, item => {
                    const rect = item.getBoundingClientRect();
                    const itemX = rect.left;
                    const itemY = rect.top;

                    //console.log("startX is:" + endX);    
    
                    if (
                        itemX <= Math.max(startX, endX) &&
                        itemX + rect.width >= Math.min(startX, endX) &&
                        itemY <= Math.max(startY, endY) &&
                        itemY + rect.height >= Math.min(startY, endY)
                    ) {
                        if (!item.classList.contains('selected') && selectedCount < maxSelectable) {
                            //console.log('mouse selected');
                            item.classList.add('selected');
                            selectedCount++;
                        }
                    } else {
                        if (item.classList.contains('selected')) {
                            item.classList.remove('selected');
                            selectedCount--;
                        }
                    }
                });
                
                updateSquareOverlay(event);
            }
        }
    
        function handleItemMouseUp() {
            //console.log('mouse up1');
            isMouseDown = false;
            grid.removeEventListener('mousemove', handleItemMouseMove);
            clearSquareOverlay();
        }
    
        function clearSelection() {
            Array.prototype.forEach.call(items, item => {
                item.classList.remove('selected');
            });
            selectedCount = 0;
        }
    
        function selectItemsInRange(selectedItem) {
            let startIndex = Array.prototype.indexOf.call(items, lastSelectedItem);
            let endIndex = Array.prototype.indexOf.call(items, selectedItem);
            let count = 0;
    
            if (startIndex > endIndex) {
                const temp = startIndex;
                startIndex = endIndex;
                endIndex = temp;
            }
    
            for (let i = startIndex; i <= endIndex; i++) {
                if (!items[i].classList.contains('selected') && count < maxSelectable) {
                    items[i].classList.add('selected');
                    count++;
                }
            }
            selectedCount = count;
        }
        
        function updateSquareOverlay(event) {

            //startX = event.clientX;
            //startY = event.clientY;
            endX = event.clientX;
            endY = event.clientY;

            squareOverlay.style.display = 'block';
            squareOverlay.style.left = Math.min(startX, endX) + 'px';
            squareOverlay.style.top = Math.min(startY, endY) + 'px';
            squareOverlay.style.width = Math.abs(endX - startX) + 'px';
            squareOverlay.style.height = Math.abs(endY - startY) + 'px';
        }
    
        function clearSquareOverlay() {
            squareOverlay.style.display = 'none';
        }
    
        Array.prototype.forEach.call(items, item => {
            item.addEventListener('mousedown', handleItemMouseDown);
        });
    
        grid.addEventListener('mouseup', handleItemMouseUp);
        

    };


  return (elements);
  
}

export default Grid