import { useState } from 'react';
import ProfileEditFields from '../components/manage-items/EditFields';
import MonthlyCosts from '../components/MonthlyCosts';
import { useAuth } from "../hooks/useAuth";
import { userProfileLabels } from '../constants';

const Profile = () => {
  const { logout, user } = useAuth();

  const [fields, setFields] = useState(user.userInfo);

  return (
    <div className='flex flex-align-center-v'>

    <MonthlyCosts 
        user={user}
    />

    <ProfileEditFields
      fields={fields}
      labels={userProfileLabels}
      setFields={setFields}
    />

    {/* { <button className='button' onClick={logout}>Logout</button> } */}

    </div>    
    
  );
};

export default Profile;
