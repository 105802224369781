import { useEffect } from 'react';

const RegisterForm = ({ props, setErrors }) => { 

  useEffect(() => {
    // If there are errors, set a timeout to clear them after 5 seconds
    if (props.errors) {
      const timer = setTimeout(() => {
        setErrors({});
      }, 5000);

      // Clear the timeout if the component is unmounted or if the user clicks an input field
      return () => clearTimeout(timer);
    }
  }, [props.errors]);

  const handleFocus = () => {
    // Clear the errors when an input is focused
    setErrors({});
  };

  if (props.submitted) {
    return (
    <div className='flex flex-align-center-h flex-align-center-v'>            
      <form className='wrapper-desktop card card-large' onSubmit={props.handleUserConfirmation}>
        <p>En kod skickades till {props.formData.email}, skriv in koden i fältet nedan.</p>
        <input 
          type="text"
          name="code"
          placeholder='Skriv in koden här'
          value={props.formData.code}
          onChange={props.handleChange}>
        </input>                      
        <p>Ifall du inte fick någon kod dubbelkolla att e-postadressen är korrek och att den inte hamnade i skräpposten.</p>
        <br></br>
        <button className='button' type="submit">Bekräfta</button>
      </form>
      </div>
    );
  }

  return (
    <div className='flex flex-align-center-h flex-align-center-v'>             
        <form className='wrapper-desktop card card-large register-form' onSubmit={props.handleSubmit}>
          <h1 style={{marginBottom: '22px'}}>Fyll i följande uppgifter</h1> 
          <div>
            <label>
              Förnamn:
              <input
                type="text"
                name="firstname"
                value={props.formData.firstname}
                onChange={props.handleChange}
                onFocus={handleFocus}
              />
            </label>
            {props.errors.firstname && <p>{props.errors.firstname}</p>}
          </div>
          <div>
            <label>
              Efternamn:
              <input
                type="text"
                name="lastname"
                value={props.formData.lastname}
                onChange={props.handleChange}
                onFocus={handleFocus}
              />
            </label>
            {props.errors.lastname && <p>{props.errors.lastname}</p>}
          </div>
          <div>
            <label>
              Email:
              <input
                type="email"
                name="email"
                value={props.formData.email}
                onChange={props.handleChange}
                onFocus={handleFocus}
              />
            </label>
            {props.errors.email && <p>{props.errors.email}</p>}
          </div>
          <div>
            <label>
              Telefon:
              <input
                type="text"
                name="tel"
                value={props.formData.tel}
                onChange={props.handleChange}
                onFocus={handleFocus}
              />
            </label>
            {props.errors.tel && <p>{props.errors.tel}</p>}
          </div>
          <div>
            <label>
              Adress:
              <input
                type="text"
                name="adress"
                value={props.formData.adress}
                onChange={props.handleChange}
                onFocus={handleFocus}
              />
            </label>
            {props.errors.adress && <p>{props.errors.adress}</p>}
          </div>
          <div>
            <label>
              Postnummer:
              <input
                type="numer"
                name="postnummer"
                value={props.formData.postnummer }
                onChange={props.handleChange}
                onFocus={handleFocus}
              />
            </label>
            {props.errors.postnummer  && <p>{props.errors.postnummer }</p>}
          </div>
          <div>
            <label>
              Jag är:
            </label>
            <div className="radio-button">
              <label htmlFor="html">Vuxen</label>
              <input type="radio" id="adult" name="member_age_group" value="adult" defaultChecked /><br />
              <label htmlFor="css">Studerande eller mindreårig</label>
              <input type="radio" id="other" name="member_age_group" value="other" />
            </div>
          </div>
          <div>
            <label>
              Lösenord:
              <input
                type="password"
                name="password"
                value={props.formData.password}
                onChange={props.handleChange}
                onFocus={handleFocus}
              />
            </label>
            {props.errors.password && <p>{props.errors.password}</p>}
          </div>
          <div>
            <label>
              Bekräfta lösenord:
              <input
                type="password"
                name="confirmPassword"
                value={props.formData.confirmPassword}
                onChange={props.handleChange}
                onFocus={handleFocus}
              />
            </label>
            {props.errors.confirmPassword && <p>{props.errors.confirmPassword}</p>}
          </div>
          <button className='button' type="submit">Register</button>
        </form>
      </div>    
  );
};

export default RegisterForm;
