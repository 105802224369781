import { useState } from 'react';
import sprite from '../../images/icons.svg';

const SelectableCard = ({ availableItems, bookedItems, cardType, handleCheckboxCheck, slide, buttonStyle, selectedDate }) => {
      
    const [tooltip, setTooltip] = useState({ visible: false, x: 0, y: 0, text: '' });

    const handleInputClick = (e, isFull, isDisabled) => {

        // Kollar ifall tiden fins i bookeItems eller om det fins en diabled, hidden, input 
        // då max två tider är valda
        if ( Array.from(isFull).some(input => input.disabled) || isDisabled ) {

            let inputText = ''
            if(isFull) {
                inputText = 'Max två bord kan väljas, avmarkera en för att välja en annan.'
            }
            if(isDisabled) {
                inputText = 'Inga lediga bord för denna tid.'
            }

            const rect = e.target.getBoundingClientRect();
            setTooltip({
                visible: true,
                x: rect.x + window.scrollX + rect.width / 2,
                y: rect.y + window.scrollY,
                text: inputText,
            });
            setTimeout(() => {
                setTooltip({ visible: false, x: 0, y: 0, text: '' });
            }, 2000)
        } else {
            setTooltip({ visible: false, x: 0, y: 0, text: '' });
        }
        
    };

    const itemsId = availableItems.map(item => item.id);
    const itemsName = availableItems.map(item => item.type_of_object);
    //const priceGroup = availableItems.map(item => item.type_of_object);
    
    
    const currentTime = new Date().toTimeString().slice(0, 5);
    const currentDate = new Date();

    const isTimeBeforeCurrent = (time) => {        
        // 00:00–01:00 ses som en tid på morgon och inte nästa dag
        // den är ju däremot alltid bokningsbar oberoende, väl?
        if(time === '00:00–01:00' || currentDate.toDateString() > selectedDate.toDateString()) {
            return true;
        }

        // Split the provided time range (e.g., '15:00–16:00') to get start time
        const [startTime] = time.split('–');
        
        // Create a new Date object with the selected date and the start time
        const [hours, minutes] = startTime.split(':');
        const timeDate = new Date(selectedDate);
        timeDate.setHours(hours, minutes, 0, 0); // Set hours and minutes from the time

        // Subtract 15 minutes from the start time
        const timeBefore15Minutes = new Date(timeDate.getTime() - 15 * 60 * 1000).toTimeString().slice(0, 5);

        // Compare current time with the adjusted time (15 minutes before start time)

        //console.log(currentTime , timeBefore15Minutes);

        // if(currentTime >= timeBefore15Minutes) {
        //     console.log(true);
        // }
        // else {
        //     console.log(false);
        // }

        return currentTime <= timeBefore15Minutes;
    };
 
    return (
        <>       
            {itemsId.map((value, index) => (
                <div 
                    key={index} 
                    
                    className={`card card-smal card-time flex flex-align-center-h ${bookedItems.includes(value) || !isTimeBeforeCurrent(itemsName[index]) ? 'unselectable ':'selectable '} ${
                        itemsName[index] === 'Pool 9' ||
                        itemsName[index] === 'Pool 10' ||
                        itemsName[index] === 'Pool 11' ||
                        itemsName[index] === 'Darts 3' ||
                        itemsName[index] === 'Darts 4' ||
                        itemsName[index] === 'Darts 5' ||
                        itemsName[index] === 'Darts 6'
                         ? 'hidden':''} ${itemsName[index].substring(0,4) === 'Pool' ? 'pool':'darts'}
                        `
                    }                    
                >
                    <label className="flex flex-align-center-h" onClick={(e) => handleInputClick(e, e.currentTarget.children, bookedItems.includes(value))}>
                        <input
                            type="checkbox"
                            disabled={bookedItems.includes(value) || !isTimeBeforeCurrent(itemsName[index])}
                            data-id={`${cardType}-${value}`}
                            className={`card-smal_checbox checkbox-${cardType}`}
                            onChange={(e) => handleCheckboxCheck(value, e.target.checked,cardType)}                                                                                    
                        />
                        <span className="card-smal_circle"></span>
                        <div className="card-smal_background"></div>
                        <span className="card-smal_text">{itemsName[index]}</span>
                    </label>
                </div>
            ))}
            {tooltip.visible && (
                <div
                    className="tooltip"
                    style={{ position: 'absolute', top: `${tooltip.y}px`, left: `${tooltip.x}px`, transform: 'translate(-50%, -100%)', backgroundColor: '#333', color: '#fff', padding: '9px', borderRadius: '8px', border: '1px solid var(--main-color)' }}
                >
                    {tooltip.text}
                </div>
            )}

            <button className="button button-next" style={buttonStyle}  id="next" onClick={ () => slide('next') } >                    
                NÄSTA
                <svg width="7" height="11" >
                    <use href={sprite + "#svg-chevron-right"} />
                </svg>
            </button>
        </>
    );
}

export default SelectableCard;