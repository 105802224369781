import { useState, useEffect } from 'react';
import GridList from '../components/booking/GridList';
import DateSelector from '../components/DateSelector';
import { getItem, formatShortDate } from '../utils';

function BookingFormDesktop({selectedDate,setSelectedDate,user,availableTimes,availableTables}) {
    document.title = 'The Club - Manage bookings';

 //console.log(availableTables);
 
    const [maxSelectable, setMaxItems] = useState();
    const [bookedAllItems, setBookedAllItems] = useState([]);
    const itemsContainer = document.getElementById('items');
 
    useEffect(() => {
        if(itemsContainer){            
            const bookedItems = itemsContainer.getElementsByClassName('unselectable');
            Array.from(bookedItems).forEach(element => {         
                element.classList.remove('unselectable');
            });

            const selectedItems = itemsContainer.getElementsByClassName('selected');
            Array.from(selectedItems).forEach(element => {         
                element.classList.remove('selected');
            });
        } 

        const fetchAllData = async () => {
            try {        
                const response = await getItem({
                    date: formatShortDate(selectedDate, 'unformated'),
                    action: 'get-all-booked-items'
                });    
                setBookedAllItems(response);
            } catch (error) {
                console.error('Error fetching booked items:', error);
            }
        };
        fetchAllData();
    }, [selectedDate]);

    useEffect(() => {
        const getMaxSelectable = async () => {
            try {        
                const response = await getItem({
                  action: 'get-user-level',
                  userLevel: user.userInfo.userLevel
                });        
                setMaxItems(response.maxSelectable);
            } catch (error) {
                console.error('Error fetching active booking:', error);
            }
        };
        getMaxSelectable();
    }, []);

    const deactivateBookings = async () => {
        const tempBookingData = updateSelectedItems('set-booking');
        try {        
            await getItem(tempBookingData);
        } catch (error) {
            console.error('Error fetching booked items:', error);
        }
    };
    
    const removeBookings = async () => {
        const tempBookingData = updateSelectedItems('remove-booking');        
        try {        
            await getItem(tempBookingData);
        } catch (error) {
            console.error('Error fetching booked items:', error);
        }
    };
   
    const updateSelectedItems = (action) => {

        let bookingTempData = { 
            action,
            tableId: [],
            date: formatShortDate(selectedDate, 'unformated'),
            timeId: [],
            email: user.userInfo.email,
        }
          
        const selectedElements = itemsContainer.getElementsByClassName('selected');
        const times = [];
        const tables = [];

        Array.from(selectedElements).forEach(element => {

            const time = element.getAttribute('data-id-time');
            const table = element.getAttribute('data-id-table');

            if(action === 'set-booking'){
                element.classList.add('unselectable');
                element.classList.remove('selected');
            }
            if(action === 'remove-booking'){
                element.classList.remove('booked','unselectable','selected');
            }

            times.push(time);
            tables.push(table);

           
        });
    
        bookingTempData.timeId = times;
        bookingTempData.tableId = tables;

        

        return bookingTempData;
      };

  return(   
    <div id="booking-desktop-container">
        
    <div className="small-card-screen flex flex-column center" style={{width:'95%'}}>
        <h2>Välj tidpunkt</h2>

        <DateSelector 
             selectedDate={selectedDate} 
             setSelectedDate={setSelectedDate}
             type={'desktop'}
        />  
        <div id="desktop-grid-items" className="flex">

{/*             <div id="list-times">
                <div className="list-item list-item-header">Tid</div>
                <div className="list-item list-item-time">07.00-08.00</div>
                <div className="list-item list-item-time">08.00-09.00</div>
                <div className="list-item list-item-time">09.00-10.00</div>
                <div className="list-item list-item-time">10.00-11.00</div>
                <div className="list-item list-item-time">11.00-12.00</div>
                <div className="list-item list-item-time">12.00-13.00</div>
                <div className="list-item list-item-time">13.00-14.00</div>
                <div className="list-item list-item-time">14.00-15.00</div>
                <div className="list-item list-item-time">15.00-16.00</div>
                <div className="list-item list-item-time">16.00-17.00</div>
                <div className="list-item list-item-time">17.00-18.00</div>
                <div className="list-item list-item-time">18.00-19.00</div>
                <div className="list-item list-item-time">19.00-20.00</div>
                <div className="list-item list-item-time">20.00-21.00</div>
                <div className="list-item list-item-time">21.00-22.00</div>
                <div className="list-item list-item-time">22.00-23.00</div>
                <div className="list-item list-item-time">23.00-00.00</div>
                <div className="list-item list-item-time">00.00-01.00</div>
            </div> */}


            <div id="items" className='grid flex-1'>

  
                
                <div className="list-item list-item-header">Pool 1</div>
                <div className="list-item list-item-header">Pool 2</div>
                <div className="list-item list-item-header">Pool 3</div>
                <div className="list-item list-item-header">Pool 4</div>
                <div className="list-item list-item-header">Pool 5</div>
                <div className="list-item list-item-header">Pool 6</div>
                <div className="list-item list-item-header">Pool 7</div>
                <div className="list-item list-item-header">Pool 8</div>
                <div className="list-item list-item-header">Pool 9</div>
                <div className="list-item list-item-header">Pool 10</div>
                <div className="list-item list-item-header">Pool 11</div>
                <div className="list-item list-item-header">Darts 1</div>
                <div className="list-item list-item-header">Darts 2</div>
                <div className="list-item list-item-header">Darts 3</div>
                <div className="list-item list-item-header">Darts 4</div>
                <div className="list-item list-item-header">Darts 5</div>

                {bookedAllItems && availableTables? (
                    <GridList 
                    maxItems={289}
                    maxSelectable={maxSelectable}
                    bookedAllItems={bookedAllItems}
                    availableTimes={availableTimes}
                    availableTables={availableTables}
                    /> 
                    ) : (
                        <div className="spinner"></div>
                    )
                }
                
                                                  
            </div>     
        </div>
        <div style={{marginBottom: '80px',justifyContent: 'center'}} className='flex'>
                <button className='button' style={{width:"200px",marginRight: '20px',backgroundColor: "#66be6e"}} onClick={deactivateBookings}>Boka</button>
                <button className='button' style={{ width:"200px",left:"325px",backgroundColor: "#d02b2b"}} onClick={removeBookings}>Radera bookning</button>
            </div>

    </div>
</div>
    
  );
}

export default BookingFormDesktop