import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import  { getItem }  from '../utils';

const MonthlyCosts = ({user}) => {
    let navigate = useNavigate(); 
    const [visitorOtherPrice, setVisitorOtherPrice] = useState();

    const getBookings = async () => {
        const response = await getItem({ 
            action: 'get-user-bookings',
            email: user.userInfo.email
        });
        console.log(response);
    };  
    getBookings() 
       
    return (
    <div style={{marginRight: '40px'}}  className="card card-large wrapper-desktop">

        <span>Spelavgifter: ??€</span><br />
        <span>Medlemsavgift: ??€</span>

        <button className="button" id="button-confirm" onClick={ () => navigate('/active-bookings')} style={{marginTop: "19px"}}>
            Aktiva bokningar
        </button>

        <span>Bokningar denna månad</span><br />

        <span>Bokningar från andra medlemmar denna månad</span>

    </div>
    );
};

export default MonthlyCosts;