import { useState, useEffect } from 'react';
//import { useCheckScreenSize } from '../hooks/checkScreenSize';
import BookingFormMobile from './BookingFormMobile';
import BookingFormDesktop from './BookingFormDesktop';
import  { getItem }  from '../utils';
import { useAuth } from "../hooks/useAuth";
import { availableTimes } from '../constants';

const BookingForm = ({type}) => {

    const { user } = useAuth();
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [availableTables, setAvailableTables] = useState([]);    
    //const isMobile = useCheckScreenSize();
    
      // Samma fins i ActivBookingCard
      useEffect(() => {        
        const fetchPriceGroups = async () => {
            const response = await getItem({ 
                action: "get-all-objects",
                bookable: true,
            });
            setAvailableTables(response);
        };
        
        fetchPriceGroups();        
    }, []);
   
    
    return (
      <>     
        {type === 'mobile'?
        <BookingFormMobile
            selectedDate={selectedDate}            
            setSelectedDate={setSelectedDate}
            isMobile={true}
            user={user}
            availableTimes={availableTimes}
            availableTables={availableTables}
        />   
        :                
        <BookingFormDesktop 
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            user={user}
            availableTimes={availableTimes}
            availableTables={availableTables}
        />
        } 
      </>
    );
  };
  
  export default BookingForm;